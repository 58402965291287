export default {
  title: 'COUTURME',
  titleTemplate: '%s | COUTURME',
  htmlAttrs: {
    lang: 'en'
  },
  meta: [
    { charset: 'utf-8' },
    { vmid: 'description', name: 'description', content: 'COUTURME brings the future of fashion to you today: high fashion, unparalleled quality of and a perfect fit and design to flatter your body and work with your budget.' },
    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    { name: 'robots', content: 'index, follow' },
    { name: 'mobile-web-app-capable', content: 'yes' },
    { name: 'theme-color', content: '#ffffff' },
    { name: 'apple-mobile-web-app-status-bar-style', content: '#ffffff' },
    { property: 'og:image', content: 'https://couturme.com/favicon/share-logo.png' },
    { property: 'og:image:type', content: 'image/png' },
    { property: 'og:image:width', content: '1500' },
    { property: 'og:image:height', content: '550' },
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: 'https://couturme.com/' },
    { property: 'og:title', content: 'COUTURME' },
    { property: 'og:description', content: 'COUTURME brings the future of fashion to you today: high fashion, unparalleled quality of and a perfect fit and design to flatter your body and work with your budget.' }
  ],
  link: [
    { rel: 'icon', type: 'image/png', href: '/favicon/favicon-32x32.png', sizes: '32x32' },
    { rel: 'icon', type: 'image/png', href: '/favicon/favicon-16x16.png', sizes: '16x16' },
    { rel: 'apple-touch-icon', href: '/favicon/apple-touch-icon.png' },
    { rel: 'apple-touch-startup-image', href: '/favicon/apple_splash_2048.png', sizes: '2048x2732' },
    { rel: 'apple-touch-startup-image', href: '/favicon/apple_splash_1668.png', sizes: '1668x2224' },
    { rel: 'apple-touch-startup-image', href: '/favicon/apple_splash_1536.png', sizes: '1536x2048' },
    { rel: 'apple-touch-startup-image', href: '/favicon/apple_splash_1125.png', sizes: '1125x2436' },
    { rel: 'apple-touch-startup-image', href: '/favicon/apple_splash_1242.png', sizes: '1242x2208' },
    { rel: 'apple-touch-startup-image', href: '/favicon/apple_splash_750.png', sizes: '750x1334' },
    { rel: 'apple-touch-startup-image', href: '/favicon/apple_splash_640.png', sizes: '640x1136' },
    { rel: 'stylesheet', href: 'https://fonts.googleapis.com/icon?family=Material+Icons' }
  ]
}
