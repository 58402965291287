<template>
  <router-link to="/" title="Home Page" class="no-underline inline-flex logo-text__wrapper">
    <img src="../assets/cm-logo.png" :width="width" :height="height" alt="logo" class="logo-image"
         :class="{'-inverted': invert}"
    >
  </router-link>
</template>

<script>
export default {
  props: {
    width: {
      type: [String, Number],
      required: true
    },
    height: {
      type: [String, Number],
      required: true
    },
    invert: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style scoped type="text/scss" lang="scss">
  .logo {
    &-text {
      font-size: 22px;
      img
      &__sub {
        font-size: 12px;
      }
      &__wrapper {
        flex-direction: column;
      }
    }
    &-image {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      @include respond-to(tablet) {
        max-height: unset;
      }
      &.-inverted {
        filter: invert(100%);
      }
    }
  }
</style>
