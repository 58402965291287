<template>
  <button type="button" class="inline-flex brdr-none" @click="goBack" data-testid="returnButton">
    <i class="material-icons">keyboard_backspace</i>
  </button>
</template>

<script>

export default {
  props: {
    to: {
      type: [String, Object],
      default: null
    }
  },
  methods: {
    goBack () {
      if (this.to) {
        this.$router.push(this.to)
      } else {
        this.$router.back()
      }
    }
  }
}
</script>
