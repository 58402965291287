<template>
  <div class="minimal-layout">
    <div id="viewport" class="w-100 relative">
      <minimal-header />
      <slot />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import MinimalHeader from '../components/blocks/Header/MinimalHeader.vue'

import Head from '../resource/head'

export default {
  data () {
    return {
      ordersData: []
    }
  },
  computed: {
    ...mapState({
      overlayActive: state => state.ui.overlay
    })
  },
  methods: {
  },
  beforeMount () {
  },
  beforeDestroy () {
  },
  metaInfo: Head,
  components: {
    MinimalHeader
  }
}
</script>
